.previewImage {
    width: 250px !important;
    min-width: 250px;
}

.flexBox {
    flex-direction: row;
}

.cardContent {
    margin-left: 1em;
}


@media (max-width: 600px) {
    .previewImage {
        width: 100% !important;
    }

    .flexBox {
        flex-direction: column;
    }

    .cardContent {
        margin-top: 1em;
    }
}